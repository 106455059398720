import { default as changeIP7C242gTuMeta } from "/tmp/build_6803c167/pages/account/change.vue?macro=true";
import { default as indextXPDq9QmCHMeta } from "/tmp/build_6803c167/pages/account/index.vue?macro=true";
import { default as loginJ0oL4WK3qVMeta } from "/tmp/build_6803c167/pages/account/login.vue?macro=true";
import { default as _91token_93oVkhoT8tSbMeta } from "/tmp/build_6803c167/pages/account/password-reset/[uidb64]/[token].vue?macro=true";
import { default as complete5km9wNiCmLMeta } from "/tmp/build_6803c167/pages/account/password-reset/complete.vue?macro=true";
import { default as donemZbWBIl7tZMeta } from "/tmp/build_6803c167/pages/account/password-reset/done.vue?macro=true";
import { default as indexjiWVdBqtsVMeta } from "/tmp/build_6803c167/pages/account/password-reset/index.vue?macro=true";
import { default as password_45resetRixT0hIW3wMeta } from "/tmp/build_6803c167/pages/account/password-reset.vue?macro=true";
import { default as password4tAZf7tszhMeta } from "/tmp/build_6803c167/pages/account/password.vue?macro=true";
import { default as register1PRr0VPSmcMeta } from "/tmp/build_6803c167/pages/account/register.vue?macro=true";
import { default as contactosXWwpYfI3Meta } from "/tmp/build_6803c167/pages/contact.vue?macro=true";
import { default as cookie_45policyL5Ry4AA40jMeta } from "/tmp/build_6803c167/pages/docs/cookie-policy.vue?macro=true";
import { default as privacy_45policy8CapYUhClJMeta } from "/tmp/build_6803c167/pages/docs/privacy-policy.vue?macro=true";
import { default as terms_45of_45usevoEbgbdL3cMeta } from "/tmp/build_6803c167/pages/docs/terms-of-use.vue?macro=true";
import { default as _91slug_93gxlYIzlkyzMeta } from "/tmp/build_6803c167/pages/events/[slug].vue?macro=true";
import { default as indexv13Cd50BYiMeta } from "/tmp/build_6803c167/pages/events/index.vue?macro=true";
import { default as indexUQ4pQQfAPsMeta } from "/tmp/build_6803c167/pages/index.vue?macro=true";
import { default as _91id_93etU7KQVdHzMeta } from "/tmp/build_6803c167/pages/orders/[id].vue?macro=true";
import { default as indexaX7OaZEPJCMeta } from "/tmp/build_6803c167/pages/orders/index.vue?macro=true";
import { default as indexuk8EMvseZGMeta } from "/tmp/build_6803c167/pages/purchase/index.vue?macro=true";
export default [
  {
    name: "account-change",
    path: "/account/change",
    meta: changeIP7C242gTuMeta || {},
    component: () => import("/tmp/build_6803c167/pages/account/change.vue")
  },
  {
    name: "account",
    path: "/account",
    meta: indextXPDq9QmCHMeta || {},
    component: () => import("/tmp/build_6803c167/pages/account/index.vue")
  },
  {
    name: "account-login",
    path: "/account/login",
    meta: loginJ0oL4WK3qVMeta || {},
    component: () => import("/tmp/build_6803c167/pages/account/login.vue")
  },
  {
    name: password_45resetRixT0hIW3wMeta?.name,
    path: "/account/password-reset",
    meta: password_45resetRixT0hIW3wMeta || {},
    component: () => import("/tmp/build_6803c167/pages/account/password-reset.vue"),
    children: [
  {
    name: "account-password-reset-uidb64-token",
    path: ":uidb64()/:token()",
    component: () => import("/tmp/build_6803c167/pages/account/password-reset/[uidb64]/[token].vue")
  },
  {
    name: "account-password-reset-complete",
    path: "complete",
    component: () => import("/tmp/build_6803c167/pages/account/password-reset/complete.vue")
  },
  {
    name: "account-password-reset-done",
    path: "done",
    component: () => import("/tmp/build_6803c167/pages/account/password-reset/done.vue")
  },
  {
    name: "account-password-reset",
    path: "",
    component: () => import("/tmp/build_6803c167/pages/account/password-reset/index.vue")
  }
]
  },
  {
    name: "account-password",
    path: "/account/password",
    meta: password4tAZf7tszhMeta || {},
    component: () => import("/tmp/build_6803c167/pages/account/password.vue")
  },
  {
    name: "account-register",
    path: "/account/register",
    meta: register1PRr0VPSmcMeta || {},
    component: () => import("/tmp/build_6803c167/pages/account/register.vue")
  },
  {
    name: "contact",
    path: "/contact",
    component: () => import("/tmp/build_6803c167/pages/contact.vue")
  },
  {
    name: "docs-cookie-policy",
    path: "/docs/cookie-policy",
    component: () => import("/tmp/build_6803c167/pages/docs/cookie-policy.vue")
  },
  {
    name: "docs-privacy-policy",
    path: "/docs/privacy-policy",
    component: () => import("/tmp/build_6803c167/pages/docs/privacy-policy.vue")
  },
  {
    name: "docs-terms-of-use",
    path: "/docs/terms-of-use",
    component: () => import("/tmp/build_6803c167/pages/docs/terms-of-use.vue")
  },
  {
    name: "events-slug",
    path: "/events/:slug()",
    component: () => import("/tmp/build_6803c167/pages/events/[slug].vue")
  },
  {
    name: "events",
    path: "/events",
    component: () => import("/tmp/build_6803c167/pages/events/index.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/tmp/build_6803c167/pages/index.vue")
  },
  {
    name: "orders-id",
    path: "/orders/:id()",
    meta: _91id_93etU7KQVdHzMeta || {},
    component: () => import("/tmp/build_6803c167/pages/orders/[id].vue")
  },
  {
    name: "orders",
    path: "/orders",
    meta: indexaX7OaZEPJCMeta || {},
    component: () => import("/tmp/build_6803c167/pages/orders/index.vue")
  },
  {
    name: "purchase",
    path: "/purchase",
    component: () => import("/tmp/build_6803c167/pages/purchase/index.vue")
  }
]